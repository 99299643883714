<template>
  <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />

      <div class="row">
        <div class="col-md-6">
          <h2 class="titulo-links-escolha">Permissões</h2>
        </div>
        <!--<div class="col-md-6">
          <pm-Button type="button" style="float: right;margin-top: 30px;" 
            @click="registar()" label="CADASTRAR PERMISSÃO"
            class="p-button-success" 
          ></pm-Button>
        </div>-->
        <div class="pm-card" v-if="acesso.visualizar">
          <pm-DataTable :value="permissaoALL" id="servidores"  dataKey="id" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
            :globalFilterFields="['funcao']" responsiveLayout="scroll">
              <template #header>
                <div class="p-fluid formgrid grid" style="font-size:12px">
                  <div class="field col-12 md:col-4 lg:col-3" style="margin:0px;">
                    <label style="font-weight: 400 !important;margin-top: 15px;">  </label>
                      <span class="p-input-icon-left ">
                        <i class="pi pi-search" />
                        <pm-InputText v-model="pesquisa" @input="pesquisar" placeholder="Pesquisar Nome da função"/>
                      </span>
                  </div>
                </div> 
              </template>
              <pm-Column v-for="col of itens"  sortable :field="col.key" :header="col.label" :key="col.key" style="font-size: 14px;vertical-align:middle !important">
                <template #body="{data}" >
                  <div v-if="col.key == 'funcao'">
                    {{data.funcao}}
                  </div>
             
                  <div class="colStyle" v-if="col.key == 'modulos_cadastrados'">
                  <!--  <div class="col-md-4" v-for="n in data.modulos_cadastrados" :key="n"><b>{{n.nome}} </b></div>
                    <div><b>{{data.modulos_cadastrados.nome}} </b></div>-->
                    <b v-for="n in data.modulos_cadastrados" :key="n">{{n.nome}}, </b>
                  </div>
                  <div v-if="col.key == 'id'">
                    <pm-Button   v-if="acesso.editar" type="button"  @click="editarPermissao(data.id)" icon="pi pi-pencil"
                      label="Editar" title="Editar" class="p-button-sm btn-color;table_style">
                    </pm-Button>
                    <pm-Button  v-if="data.modulos_cadastrados.length && acesso.deletar" title="Apagar" type="button" label="Apagar" @click="apagarPermissao(data.id)"
                      icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color" style="margin-left:5px">
                    </pm-Button>
                  </div>                   
                </template>
              </pm-Column>
          </pm-DataTable>  
        </div>
      </div>

  </section>
</template>


<script>
import { defineComponent } from "vue";
import { Permissoes } from "@/class/permissoes";
import { Verificar } from "@/class/verificar.js";
import axios from 'axios';



export default defineComponent({
 
  data() {
    return {
      permissaoALL:[],
      itens:[],
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Permissão'},
      ],
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      }
    };
  },
  computed: {
    pages() {
    
    },
  },
  methods: {
    registar() {
      this.$router.push({ name: "permissao-cadastrar" });
    },

    
    async apagarPermissao(id) {
      if (confirm("Você realmente deseja apagar as permissões?")) {
        try {
          const data = await Permissoes.remover(id);
           this.$vaToast.init({
            message: "Apagado com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 2500,
            fullWidth: false,
          })
          this.buscarPermissaoALL();
        }
        catch(e) {
            this.$vaToast.init({
            message: "Poblema ao apagar!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            color:"danger",
            duration: 2500,
            fullWidth: false,
          })
        }
      }
    },
    editarPermissao(id) {
      this.$router.push({ name: "permissao-editar", params: { idPermissao: id}});
    },
    async  buscarPermissaoALL() {
      const data = await Permissoes.buscarALL();
      this.permissaoALL = [];
      for (const el of data.data) {
        let info = {
          id: el.id,
          funcao: el.nome,
          modulos_cadastrados: [],
        }
        for (const permi of el.modulos) {
          let nv = {
            id: permi.modulo.id,
            nome: permi.modulo.nome,
          }
          info.modulos_cadastrados.push(nv);
        }
        this.permissaoALL.push(info);
      }

      const columns = [
        { key: "funcao", label:"Funções" },         
        { key: "modulos_cadastrados", label: "Modulos permissões"},                
        { key: "id", label: "Ação" },
      ];
      this.itens = columns;
    }
  },
 async beforeMount() {
    await this.buscarPermissaoALL();
    let acesso = await Verificar.AcessoLocal(5);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  
  },
});
</script>


<style lang="scss" scoped>

.colStyle {
  width:750px;
}
@media only screen and (max-width: 1534px){
 .colStyle {
     width: 200px;
  }
}

</style>
